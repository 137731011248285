import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock, StudyDesignOverview, StudyDesignArms } from '../index';

const MEL_ADVANCED = () => (
    <ComponentWrapper>
        <StudyDesignOverview
            title="Study Overview"
            description="Open-label, multicenter, active controlled trial of 834 patients"
            data={[
                [
                    {
                        title: 'Select Eligibility Criteria',
                        description: `
                            <ul>
                                <li>Age ≥18 years</li>
                                <li>Unresectable or metastatic melanoma</li>
                                <li>PD&#8288;-&#8288;L1 expression evaluation</li>
                            </ul>

                            <h6>Key Exclusion Criteria</h6>
                            <ul>
                                <li>Prior ipilimumab treatment</li>
                                <li>More than 1 prior systemic treatment for metastatic melanoma</li>
                            </ul>
                            <p>Randomization was stratified by line of therapy, ECOG PS (0 vs 1), and PD-L1 expression (≥1% of tumor cells [positive] vs <1% of tumor cells [negative]) according to an investigational use only (IUO) assay.</p>
                        `,
                        type: 'box-gradient',
                        colspan: 3
                    }
                ],
                [
                    {
                        type: 'arrow-gray',
                        colspan: 3
                    }
                ],
                [
                    {
                        title: 'Randomized (N=834)',
                        colspan: 3
                    }
                ],
                // [
                //     {
                //         type: 'arrow-gray'
                //     },
                //     {
                //         type: 'arrow-gray'
                //     },
                //     {
                //         type: 'arrow-gray'
                //     }
                // ],
                // [
                //     {
                //         title: 'Treatment',
                //         type: 'box-green box-small-title'
                //     },
                //     {
                //         title: 'Treatment',
                //         type: 'box-green box-small-title'
                //     },
                //     {
                //         title: 'Treatment',
                //         type: 'box-small-title'
                //     }
                // ],
                // [
                //     {
                //         type: 'arrow-green'
                //     },
                //     {
                //         type: 'arrow-green'
                //     },
                //     {
                //         type: 'box-hidden'
                //     }
                // ],
                // [
                //     {
                //         title: 'Treatment Duration',
                //         type: 'box-small-title',
                //         colspan: 2
                //     },
                //     {
                //         type: 'box-hidden'
                //     }
                // ],
            ]}
        />
    
        <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA Q3W",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `KEYTRUDA 10&nbsp;mg/kg IV Q3W (n=277)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Until disease progression or unacceptable toxicity`
                        }
                    ]
                ]
            },
            {
                title: "KEYTRUDA Q2W",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `KEYTRUDA 10&nbsp;mg/kg IV Q2W (n=279)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Until disease progression or unacceptable toxicity`
                        }
                    ]
                ]
            },
            {
                title: "Ipilimumab",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `Ipilimumab IV Q3W (n=278)`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray',
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Up to 4 doses or until disease progression or unacceptable toxicity`
                        }
                    ],
                ]
            }
        ]} />
    
        <StudyCopyBlock 
            title="End Points<sup>9</sup>"
            description={`
                <p>Primary End Points</p>
                <ul>
                    <li>OS</li>
                    <li>PFS as assessed by BICR using RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ</li>
                </ul>
                <p>Secondary End Points</p>
                <ul>
                    <li>ORR</li>
                    <li>Duration of Response (DoR)</li>
                </ul>
            `} 
        />

        <StudyCopyBlock 
            title="Additional Information"
            description={`
                <p>Patients with <i>BRAF</i> V600E mutation-positive melanoma were not required to have received prior <i>BRAF</i> inhibitor therapy.</p>
                <p>Patients with disease progression could receive additional doses of KEYTRUDA unless disease
                progression was symptomatic, was rapidly progressive, required urgent intervention, occurred with a decline in performance status, or was confirmed at 4 to 6 weeks with repeat imaging.</p>
                <p>Assessment of tumor status was performed at 12 weeks, then every 6 weeks through week 48,
                followed by every 12 weeks thereafter.</p>
            `} 
        />

        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <ul> 
                    <li>Median age: 62 years (range 18–89)</li>
                    <li>Male: 60%</li>
                    <li>White: 98%</li>
                    <li>No prior systemic therapy for metastatic disease: 66%</li>
                    <li>ECOG PS of 0: 69%</li>
                    <li>PD&#8288;-&#8288;L1 positive: 80%</li>
                    <li>PD&#8288;-&#8288;L1 negative: 18%</li>
                    <li>PD&#8288;-&#8288;L1 unknown using IUO assay: 2%</li>
                    <li>M1c stage disease: 65%</li>
                    <li>Normal LDH: 68%</li>
                    <li>Reported <i>BRAF</i> mutation positive melanoma: 36%
                        <ul>
                            <li>Previously treated with <i>BRAF</i> inhibitor (among patients with <i>BRAF</i> mutation-positive melanoma): 46% (n=139)</li>
                        </ul>
                    </li>
                    <li>History of brain metastases: 9%</li>
                </ul>
            `}
            definitions={`
                ECOG PS = Eastern Cooperative Oncology Group performance status; LDH = lactate dehydrogenase; PD&#8288;-&#8288;L1 = programmed death ligand 1.
            `} 
        />
    </ComponentWrapper>
);

export default MEL_ADVANCED;
