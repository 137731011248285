import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, KMCurve, SecondaryCallout, ComparisonTable, EndpointDisplay, NCCNcallout, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import MEL_ADVANCED from '../../components/StudyDesign/__study-design/mel-advanced'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-006 - Clinical Trial Results | HCP`,
    keywords: `keynote 006, advanced melanoma clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-006 in patients with advanced melanoma.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/melanoma-monotherapy/","@type":"MedicalTrial","name":"Clinical Findings from KEYNOTE⁠-⁠006","description":"A clinical trial was done to evaluate the Overall Survival (OS) of patients taking KEYTRUDA® vs Ipilimumab1. The study shows results from a 5-year extension of KEYNOTE-006.","trialDesign":"Open-label, multicenter, active controlled trial of 834 patients Select Eligibility Criteria Age ≥18 years Unresectable or metastatic melanoma PD⁠-⁠L1 expression evaluation Key Exclusion Criteria Prior ipilimumab treatment More than 1 prior systemic treatment for metastatic melanoma Randomization was stratified by line of therapy, ECOG PS (0 vs 1), and PD-L1 expression (≥1% of tumor cells [positive] vs <1% of tumor cells [negative]) according to an investigational use only (IUO) assay."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/melanoma-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/melanoma-monotherapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with unresectable or metastatic melanoma. "}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 2;
const jobCode = jobCodes[2].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Adjuvant Therapy for Melanoma', url: '/efficacy/melanoma-adjuvant-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/melanoma-monotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;006' }, 
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;006' }
]

const KMcurveOSsurvivalFootnotes = [
    {
        label: "a.",
        text: "From product-limit (Kaplan-Meier) method for censored data."
    },
    {
        label: "b.",
        text: "HR based on Cox regression model with treatment as a covariate stratified by line of therapy (first vs second), PD&#8288;-&#8288;L1 status (positive vs 	negative) and ECOG PS (0 vs 1); if no subject is in 1 of the treatment groups involved in a comparison for a particular stratum, then 	that stratum is excluded from the treatment comparison."
    },
    {
        label: "c.",
        text: "One-sided <i>P</i>-value based on log-rank test."
    },
]

const KMcurveOSsurvivalDefinitions = "CI = confidence interval; ECOG PS = Eastern Cooperative Oncology Group performance status; HR = hazard ratio; IV = intravenous; Q2W = every 2 weeks; Q3W = every 3 weeks."

const twoYearOSrateSecondaryCalloutFootnotes = [
    {
        label: "d.",
        text: "KEYTRUDA 10&nbsp;mg/kg Q3W and Q2W."
    }
]

const osSurvivalComparisonTableFootnotes = [
    {
        label: "e.",
        text: "From product-limit (Kaplan-Meier) method for censored data."
    },
    {
        label: "f.",
        text: "HR based on Cox regression model with treatment as a covariate stratified by line of therapy (first vs second), PD&#8288;-&#8288;L1 status (positive vs negative) and ECOG PS (0 vs 1); if no subject is in 1 of the treatment groups involved in a comparison for a particular stratum, then that stratum is excluded from the treatment comparison."
    },
    {
        label: "g.",
        text: "One-sided <i>P</i>-value based on log-rank test."
    }
]

const KMcurvepostHoc5yearFootnotes = [
    {
        label: "h.",
        text: "From product-limit (Kaplan-Meier) method for censored data."
    }
]

const osPostExtensionComparisonTableFootnotes = [
    {
        label: "i.",
        text: "Based on Cox regression model with treatment as a covariate stratified by line of therapy (first vs second), PD&#8288;-&#8288;L1 status (positive vs negative) and ECOG (0 vs 1); if no patients are in one of the treatment groups involved in a comparison for a particular stratum, then that stratum is excluded from the treatment comparison."
    },
    {
        label: "j.",
        text: "From product-limit (Kaplan-Meier) method for censored data. "
    }
]

const pfsBICR1yearComparisonTableFootnotes = [
    {
        label: "k.",
        text: "PFS was a primary endpoint with OS in KEYNOTE&#8288;-&#8288;006."
    },
    {
        label: "l.",
        text: "HR (KEYTRUDA compared to ipilimumab) based on the stratified Cox proportional hazard model."
    },
    {
        label: "m.",
        text: "Stratified log-rank."
    },
]
const pfsBICR1yearComparisonTableDefinitions = `
    <p>Percentages have been rounded to the nearest whole integer.</p>
    <p>BICR = blinded independent central review.</p>
`;

const pfsBICR48monthComparisonTableDefinitions = `
    <p>Percentages have been rounded to the nearest whole integer.</p>
    <p>irRC = immune-related response criteria.</p>
`;

const pfsBICR48monthComparisonTableFootnotes = [
    {
        label: "n.",
        text: "PFS was a primary endpoint with OS in KEYNOTE&#8288;-&#8288;006."
    },
    {
        label: "o.",
        text: "HR (KEYTRUDA compared to ipilimumab) based on the stratified Cox proportional hazard model."
    },
]

const orr1yearAnalysisEndpointDisplayFootnotes = [
    {
        label: "p.",
        text: "ORR by BICR."
    },
    {
        label: "q.",
        text: "KEYTRUDA 10 mg/kg Q3W."
    },
]
const orr1yearAnalysisEndpointDisplayDefinitions = `<p>Percentages have been rounded to the nearest whole integer.</p>
                                                    <p>Q3W = every 3 weeks.</p>`

const orr5yearAnalysisEndpointDisplayFootnotes = [
    {
        label: "r.",
        text: "ORR by irRC."
    },
    {
        label: "s.",
        text: "KEYTRUDA 10 mg/kg Q3W."
    },
]

const NCCNcalloutData = {
    bodyCopy: 'NCCN Guidelines® recommend pembrolizumab (KEYTRUDA) as an option for first‑line systemic therapy (category 1 and preferred) or second- or subsequent-line systemic therapy (category 2A and preferred) for metastatic or unresectable cutaneous melanoma.<sup>7,t,u</sup>',
    showBadge: false,
}

const NCCNcalloutFootnotes = [
    {
        label: "t.",
        text: "Testing for tumor PD&#8288;-&#8288;L1 should not guide clinical decision-making. The utility of this biomarker requires further investigation."
    },
    {
        label: "u.",
        text: "Appropriateness of single agent depends on patient fitness/frailty, comorbidities, low-volume disease, autoimmune disease history, and other factors."
    },
]

const NCCNdefinitions = `
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 1 = Based upon high-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.</p>
    <p>Category 2A = Based on lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.</p>
`
const NCCNcalloutReferences = [
    {
        label: "7.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Cutaneous Melanoma V.3.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed November 2, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const pageReferences = [
    {
        label: "1.",
        text: "Data available on request from Merck, Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-OOC-02326."
    },
    {
        label: "2.",
        text: "Schachter J, Ribas A, Long GV, et al. Pembrolizumab versus ipilimumab for advanced melanoma: final overall survival results of a multicentre, randomised, open-label phase 3 study (KEYNOTE&#8288;-&#8288;006). <i>Lancet.</i> 2017;390(10105):1853-1862. doi:10.1016/S0140-6736(17)31601-X"
    },
    {
        label: "3.",
        text: "Robert C, Ribas A, Schachter J, et al. Supplementary Appendix to: Pembrolizumab versus ipilimumab in advanced melanoma (KEYNOTE&#8288;-&#8288;006): post-hoc 5-year results from an open-label, multicentre, randomised, controlled, phase 3 study. <i>Lancet Oncol.</i> 2019;1–13. doi:10.1016/S1470-2045(19)30388-2"
    },
    {
        label: "4.",
        text: "Data available on request from Merck, Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-OOC-02325."
    },
    {
        label: "5.",
        text: "Robert C, Ribas A, Schachter J, et al. Pembrolizumab versus ipilimumab in advanced melanoma (KEYNOTE&#8288;-&#8288;006): post-hoc 5-year results from an open-label, multicentre, randomised, controlled, phase 3 study. <i>Lancet Oncol.</i> 2019;20(9):1239-1251. doi:10.1016/S1470-2045(19)30388"
    },
    {
        label: "6.",
        text: "Robert C, Schachter J, Long GV, et al. Supplementary Appendix to: Pembrolizumab versus ipilimumab in advanced melanoma. <i>N Engl J Med.</i> 2015;372(26):2521–2532."
    },
    {
        label: "7.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Cutaneous Melanoma V.3.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed November 2, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
    {
        label: "8.",
        text: "ClinicalTrials.gov. Identifier NCT01866319. Study to evaluate the safety and efficacy of two different dosing schedules of pembrolizumab (MK-3475) compared to ipilimumab."
    },
    {
        label: "9.",
        text: "Robert C, Schachter J, Long GV, et al. Pembrolizumab versus ipilimumab in advanced melanoma. <i>N Engl J Med.</i> 2015;372(26):2521–2532. doi:10.1056/NEJMoa1503093"
    },
]


const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Superior Overall Survival (OS) vs Ipilimumab<sup>1</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <KMCurve
                                title="Kaplan-Meier Estimates of Overall Survival (OS)<sup>a</sup> in KEYNOTE&#8288;-&#8288;006<sup>1,2</sup>"
                                footnotes={KMcurveOSsurvivalFootnotes}
                                definitions={KMcurveOSsurvivalDefinitions}
                                image={() => <StaticImage src="../../assets/mel-advanced-006-km-v-4.png"
                                    placeholder="none"
                                    alt="Kaplan-Meier Estimates of OS in Patients With Advanced Melanoma With KEYTRUDA® (pembrolizumab) in KEYNOTE-006"/>}
                            />

                            <SecondaryCallout
                                title="55% 2-year OS rate with KEYTRUDA<sup>d</sup> vs 43% with ipilimumab<sup>1,3</sup>"
                                text="Number of OS events: 119/277 (43%) and 122/279 (44%) with KEYTRUDA 10&nbsp;mg/kg Q3W and Q2W, respectively, and 142/278 (51%) with ipilimumab"
                                footnotes={twoYearOSrateSecondaryCalloutFootnotes}
                                alt=""
                            />

                            <ComparisonTable title="Overall Survival (OS)<sup>1,e</sup>" footnotes={osSurvivalComparisonTableFootnotes} data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q3W (n=277)',
                                        },
                                        {
                                            legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q2W (n=279)',
                                        },
                                        {
                                            legendTitle: 'Ipilimumab IV (n=278)',
                                        }
                                    ],

                                    [
                                        {
                                            hrCiP: 'HR<sup>f</sup>=0.68; 95% CI, 0.53–0.86; <i>P</i>=0.00083<sup>g</sup>'
                                        },
                                        {
                                            hrCiP: 'HR<sup>f</sup>=0.68; 95% CI, 0.53–0.87; <i>P</i>=0.00085<sup>g</sup>'
                                        },
                                        {
                                            hrCiP: '—'
                                        }
                                    ],
                                    [
                                        {
                                            label: 'Median OS',
                                            dataPoint: 'Not reached (NR)',
                                            dataSubPoint: '(95% CI, 23.5–NR)',
                                        },
                                        {
                                            label: 'Median OS',
                                            dataPoint: 'NR',
                                            dataSubPoint: '(95% CI, 22.1–NR)',
                                        },
                                        {
                                            label: 'Median OS',
                                            dataPoint: '16.0 months',
                                            dataSubPoint: '(95% CI, 13.5–22.0)'
                                        },
                                    ],
                                ]
                            } />

                            <PageSubSection title="Durable OS in a Post hoc 5-Year Extension Analysis">
                                <SecondaryCallout
                                    title="Continued to show improved OS at 5 years with 37% OS rate with KEYTRUDA 10&nbsp;mg/kg Q3W, 40% with KEYTRUDA 10&nbsp;mg/kg Q2W and 31% with ipilimumab<sup>3</sup>"
                                    alt=""
                                />

                                <KMCurve
                                    title="Kaplan-Meier Estimates of OS in KEYNOTE&#8288;-&#8288;006<sup>3–5</sup>"
                                    limitation="<b>LIMITATION:</b> The 5-year extension of KEYNOTE&#8288;-&#8288;006 was a post hoc exploratory analysis. No formal statistical testing was planned for the 5-year analysis and, therefore, no conclusions can be drawn."
                                    footnotes={KMcurvepostHoc5yearFootnotes}
                                    additionalInformation={[
                                        {
                                            title: 'Additional Information',
                                            text: `
                                                <p>Number of OS events were 161/277 (58%) and 163/279 (58%) with KEYTRUDA 10&nbsp;mg/kg Q3W and Q2W, respectively, and 172/278 (62%) with ipilimumab.<sup>h</sup></p>
                                                <p>The median follow-up time was 57.7 months in surviving patients.</p>
                                            `
                                        },
                                    ]}
                                    image={() => <StaticImage src="../../assets/Overall_Survival_ITT Population_updated_v7.png"
                                        placeholder="none"
                                        alt="Kaplan-Meier Estimates of OS in the 5-Year Extension Analysis of KEYNOTE-006" />}
                                />
                            </PageSubSection>

                            <ComparisonTable title="OS in a Post hoc 5-Year Extension Analysis<sup>3</sup>"  footnotes={osPostExtensionComparisonTableFootnotes} data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q3W (n=277)',
                                        },
                                        {
                                            legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q2W (n=279)',
                                        },
                                        {
                                            legendTitle: 'Ipilimumab IV (n=278)',
                                        }
                                    ],
                                    [
                                        {
                                            hrCiP: 'HR<sup>i</sup>=0.74 (95% CI, 0.59–0.92)'
                                        },
                                        {
                                            hrCiP: 'HR<sup>i</sup>=0.74 (95% CI, 0.59–0.91)'
                                        },
                                        {
                                            hrCiP: '—'
                                        }
                                    ],
                                    [
                                        {
                                            label: 'Median OS<sup>j</sup>',
                                            dataPoint: '34.2 months',
                                            dataSubPoint: '(95% CI, 23.5–42.7)'
                                        },
                                        {
                                            label: 'Median OS<sup>j</sup>',
                                            dataPoint: '31.1 months',
                                            dataSubPoint: '(95% CI, 22.1–45.9)'
                                        },
                                        {
                                            label: 'Median OS',
                                            dataPoint: '15.9 months',
                                            dataSubPoint: '(95% CI, 13.3–22.0)'
                                        },
                                    ],
                                ]
                            } />

                            <PageSubSection title="Subgroup Analyses of KEYNOTE&#8288;-&#8288;006">
                                <KMCurve
                                    title="Subgroup Analysis of Treatment-Naive Patients in a Post hoc 5-Year Extension Analysis of KEYNOTE&#8288;-&#8288;006<sup>3,4</sup>"
                                    limitation={`
                                        <p><b>LIMITATIONS:</b> The 5-year extension of KEYNOTE&#8288;-&#8288;006 was a post hoc exploratory analysis. No formal statistical testing was planned for the 5-year analysis and, therefore, no conclusions can be drawn.</p>
                                        <p>KEYNOTE&#8288;-&#8288;006 was not powered to detect differences in the treatment effect in these subgroups; therefore, results from exploratory subgroup analyses should be interpreted with caution because of the modest patient numbers and potential imbalances in baseline characteristics within the subgroups.</p>
                                    `}
                                    additionalInformation={[
                                        {
                                            title: 'Additional Information',
                                            text: `
                                                <p>Percentage of patients in KEYNOTE&#8288;-&#8288;006 who were treatment-naive was 67% (185/277) and 66% (183/279) with KEYTRUDA 10&nbsp;mg/kg Q3W and Q2W, respectively, and 65% (181/278) with ipilimumab.</p>
                                                <p>Patients were considered to be treatment-naive if they had never undergone treatment for metastatic disease.</p>
                                            `
                                        },
                                    ]}
                                    image={() => <StaticImage src="../../assets/mel-advanced-006-subgroup-analysis-km-v-3.png"
                                        placeholder="none"
                                        alt="Subgroup Analysis of KEYNOTE-006" />}
                                />
                            </PageSubSection>

                            <PageSubSection title="Progression-Free Survival (PFS) and Objective Response Rate (ORR)">
                                <SecondaryCallout 
                                    title="PFS with KEYTRUDA and ipilimumab at the protocol-specified first-interim analysis (6 months) and in a 5-year post hoc extension analysis<sup>4,6</sup>" 
                                    alt=""    
                                />  
                                <ComparisonTable title="6-Month Analysis<sup>6</sup>"
                                    limitation="PFS by BICR Analysis<sup>k</sup>"
                                    footnotes={pfsBICR1yearComparisonTableFootnotes}
                                    definitions={pfsBICR1yearComparisonTableDefinitions}
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q3W (n=277)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q2W (n=279)',
                                            },
                                            {
                                                legendTitle: 'Ipilimumab IV (n=278)',
                                            }
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR<sup>l</sup>=0.58; 95% CI, 0.47–0.72; <i>P</i><0.001<sup>m</sup>'
                                            },
                                            {
                                                hrCiP: 'HR<sup>l</sup>=0.58; 95% CI, 0.46–0.72; <i>P</i><0.001<sup>m</sup>'
                                            },
                                            {
                                                hrCiP: '—'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '57%',
                                                dataSubPoint: '(n=157)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '56%',
                                                dataSubPoint: '(n=157)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '68%',
                                                dataSubPoint: '(n=188)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '4.1 months',
                                                dataSubPoint: '(95% CI, 2.9–6.9)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '5.5 months',
                                                dataSubPoint: '(95% CI, 3.4–6.9)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '2.8 months',
                                                dataSubPoint: '(95% CI, 2.8–2.9)'
                                            },
                                        ],
                                    ]
                                    } />
                                <ComparisonTable title="5-Year Extension Analysis<sup>4</sup>"
                                    definitions={pfsBICR48monthComparisonTableDefinitions}
                                    footnotes={pfsBICR48monthComparisonTableFootnotes}
                                    limitation={`
                                        <p style="margin-top: 0;">PFS by irRC<sup>n</sup></p>
                                        <p style="margin-bottom: 0;"><b>LIMITATION:</b> The 5-year extension analysis of KEYNOTE&#8288;-&#8288;006 was a post hoc exploratory analysis. No formal statistical testing was planned for the 5-year analysis and, therefore, no conclusions can be drawn.</p>
                                    `}
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q3W (n=277)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg IV Q2W (n=279)',
                                            },
                                            {
                                                legendTitle: 'Ipilimumab IV (n=278)',
                                            }
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR<sup>o</sup>=0.56; 95% CI, 0.46–0.68'
                                            },
                                            {
                                                hrCiP: 'HR<sup>o</sup>=0.57; 95% CI, 0.46–0.69'
                                            },
                                            {
                                                hrCiP: '—'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '74%',
                                                dataSubPoint: '(n=205)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '74%',
                                                dataSubPoint: '(n=206)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '78%',
                                                dataSubPoint: '(n=217)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '9.7 months',
                                                dataSubPoint: '(95% CI, 5.8–12.0)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '8.4 months',
                                                dataSubPoint: '(95% CI, 5.6–13.7)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '3.4 months',
                                                dataSubPoint: '(95% CI, 2.9–4.2)'
                                            },
                                        ],
                                    ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title="ORR With KEYTRUDA and Ipilimumab at Protocol-Specified First-Interim Analysis (6 months) and in a 5-Year Post hoc Extension Analysis<sup>4,6</sup>">
                                <EndpointDisplay
                                    title="ORR: 6-Month Analysis<sup>p</sup>"
                                    limitation="In 277 patients with KEYTRUDA 10&nbsp;mg/kg Q3W and 278 patients with Ipilimumab"
                                    footnotes={orr1yearAnalysisEndpointDisplayFootnotes}
                                    definitions={orr1yearAnalysisEndpointDisplayDefinitions}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA<sup>q</sup>',
                                                rate: '33%',
                                                ci: '95% CI, 27–39',
                                                completeResponseRate: '6%',
                                                partialResponseRate: '27%',
                                            },

                                            {
                                                dataType: 'ORR',
                                                label: 'Ipilimumab',
                                                rate: '12%',
                                                ci: '95% CI, 8–16',
                                                completeResponseRate: '1%',
                                                partialResponseRate: '10%',
                                            }
                                        ]
                                    }
                                />
                                <EndpointDisplay
                                    title="ORR: 5-Year Analysis<sup>r</sup>"
                                    limitation="<p><b>LIMITATION:</b> The 5-year extension of KEYNOTE&#8288;-&#8288;006 was a post hoc exploratory analysis. No formal statistical testing was planned for the 5-year analysis and, therefore, no conclusions can be drawn.</p>
                                    <p>In 277 patients with KEYTRUDA 10&nbsp;mg/kg Q3W and 278 patients with Ipilimumab</p>"
                                    footnotes={orr5yearAnalysisEndpointDisplayFootnotes}
                                    definitions="Percentages have been rounded to the nearest whole integer."
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA<sup>s</sup>',
                                                rate: '43%',
                                                ci: '95% CI, 37.1–49.0',
                                                completeResponseRate: '14%',
                                                partialResponseRate: '29%',
                                            },

                                            {
                                                dataType: 'ORR',
                                                label: 'Ipilimumab',
                                                rate: '17%',
                                                ci: '95% CI, 12.4–21.4',
                                                completeResponseRate: '3%',
                                                partialResponseRate: '13%',
                                            }
                                        ]
                                    }
                                />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                footnotes={NCCNcalloutFootnotes}
                                definitions={NCCNdefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[2].label}>
                            <SecondaryCallout 
                                title="Pivotal Phase 3 Trial vs Ipilimumab Evaluating OS and PFS<sup>8</sup>" 
                                alt=""
                            />
                            <MEL_ADVANCED />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
